import axios from "axios";

let loading = null;

export const baseUrl = 'https://charity.lingshuihuhang.cn/'
// export const baseUrl = 'http://127.0.0.1:8000/'
const service = axios.create({
    baseURL: baseUrl,
    timeout: 3600000, // request timeout
});

// request interceptor
service.interceptors.request.use(
    (config) => {
        // do something before request is sent

        // const { showLoading } = config.myConfig;
        // if (showLoading) {
        //     loading = Loading.service({
        //         text: "Loading...",
        //         spinner: "el-icon-loading",
        //         background: "rgba(0, 0, 0, 0.7)",
        //     });
        // }
        return config;
    },
    (error) => {
        // do something with request error
        // loading && loading.close();
        console.log(error); // for debug
        return Promise.reject(error);
    }
);

// response interceptor
service.interceptors.response.use(
    (response) => {
        // loading && loading.close();
        const res = response.data;
        if (response.headers["content-disposition"] != undefined) {
            localStorage.setItem("file_name", response.headers["content-disposition"]);
        }
        const result = res.code;
        if (result === undefined) {
            return response;
        }
        if (result !== 0 && result !== 1) {
            // Message({
            //     message: res.msg || "Error",
            //     type: "error",
            //     duration: 5 * 1000,
            // });
            if (res.code == 443) {
                // router.push({
                //     path: "/",
                // });
            }
        }
        return res;
    },
    (error) => {
        loading && loading.close();
        // 处理网络请求失败
        console.log("err" + error); // for debug
        // Message({
        //     message: error.message,
        //     type: "error",
        //     duration: 5 * 1000,
        // });
        return Promise.reject(error);
    }
);

export default service;